var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex justify-start align-center grey lighten-3"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v("Pedigree")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","color":"blue lighten-4 blue--text"},on:{"click":function($event){return _vm.openPedigree(_vm.dog)}}},on),[_vm._v(" Save pedigree ")])]}}])},[_c('span',[_vm._v("Save pedigree")])])],1),_c('v-divider'),_c('div',{staticClass:"generate_pedigree"},[_c('a',{staticClass:"vh-button",attrs:{"id":"generatePed"}}),_c('div',{staticClass:"place_for_message"},[_vm._v(" ")]),_c('table',{attrs:{"id":"pedigree"}},[_c('tbody',[_c('tr',[_c('td',{attrs:{"rowspan":"16"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","variant":"outlined","label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_0_0'),"error-messages":_vm.errors['pedigreeArray_0_0']},on:{"change":function($event){return _vm.updateParent($event, 1, 0)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_0_0',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_0_0),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_0_0", $$v)},expression:"fields.pedigreeArray_0_0"}})],1)]),_c('td',{attrs:{"rowspan":"8"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_1_0_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_1_0'),"error-messages":_vm.errors['pedigreeArray_1_0']},on:{"change":function($event){return _vm.updateParent($event, 2, 0)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_1_0',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_1_0),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_1_0", $$v)},expression:"fields.pedigreeArray_1_0"}})],1)]),_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_0_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_0'),"error-messages":_vm.errors['pedigreeArray_2_0']},on:{"change":function($event){return _vm.updateParent($event, 3, 0)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_0',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_0),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_0", $$v)},expression:"fields.pedigreeArray_2_0"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_0_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_0'),"error-messages":_vm.errors['pedigreeArray_3_0']},on:{"change":function($event){return _vm.updateParent($event, 4, 0)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_0',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_0),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_0", $$v)},expression:"fields.pedigreeArray_3_0"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_0_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_0'),"error-messages":_vm.errors['pedigreeArray_4_0']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_0',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_0),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_0", $$v)},expression:"fields.pedigreeArray_4_0"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_1_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_1'),"error-messages":_vm.errors['pedigreeArray_4_1']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_1',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_1),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_1", $$v)},expression:"fields.pedigreeArray_4_1"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_1_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_1'),"error-messages":_vm.errors['pedigreeArray_3_1']},on:{"change":function($event){return _vm.updateParent($event, 4, 2)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_1',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_1),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_1", $$v)},expression:"fields.pedigreeArray_3_1"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_2_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_2'),"error-messages":_vm.errors['pedigreeArray_4_2']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_2',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_2),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_2", $$v)},expression:"fields.pedigreeArray_4_2"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_3_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_3'),"error-messages":_vm.errors['pedigreeArray_4_3']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_3',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_3),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_3", $$v)},expression:"fields.pedigreeArray_4_3"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_1_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_1'),"error-messages":_vm.errors['pedigreeArray_2_1']},on:{"change":function($event){return _vm.updateParent($event, 3, 2)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_1',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_1),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_1", $$v)},expression:"fields.pedigreeArray_2_1"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_2_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_2'),"error-messages":_vm.errors['pedigreeArray_3_2']},on:{"change":function($event){return _vm.updateParent($event, 4, 4)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_2',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_2),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_2", $$v)},expression:"fields.pedigreeArray_3_2"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_4_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_4'),"error-messages":_vm.errors['pedigreeArray_4_4']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_4',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_4),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_4", $$v)},expression:"fields.pedigreeArray_4_4"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_5_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_5'),"error-messages":_vm.errors['pedigreeArray_4_5']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_5',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_5),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_5", $$v)},expression:"fields.pedigreeArray_4_5"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_3_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_3'),"error-messages":_vm.errors['pedigreeArray_3_3']},on:{"change":function($event){return _vm.updateParent($event, 4, 6)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_3',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_3),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_3", $$v)},expression:"fields.pedigreeArray_3_3"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_6_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_6'),"error-messages":_vm.errors['pedigreeArray_4_6']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_6',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_6),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_6", $$v)},expression:"fields.pedigreeArray_4_6"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_7_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_7'),"error-messages":_vm.errors['pedigreeArray_4_7']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_7',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_7),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_7", $$v)},expression:"fields.pedigreeArray_4_7"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"8"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_1_1_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_1_1'),"error-messages":_vm.errors['pedigreeArray_1_1']},on:{"change":function($event){return _vm.updateParent($event, 2, 2)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_1_1',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_1_1),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_1_1", $$v)},expression:"fields.pedigreeArray_1_1"}})],1)]),_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_2_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_2'),"error-messages":_vm.errors['pedigreeArray_2_2']},on:{"change":function($event){return _vm.updateParent($event, 3, 4)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_2',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_2),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_2", $$v)},expression:"fields.pedigreeArray_2_2"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_4_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_4'),"error-messages":_vm.errors['pedigreeArray_3_4']},on:{"change":function($event){return _vm.updateParent($event, 4, 8)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_4',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_4),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_4", $$v)},expression:"fields.pedigreeArray_3_4"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_8_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_8'),"error-messages":_vm.errors['pedigreeArray_4_8']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_8',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_8),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_8", $$v)},expression:"fields.pedigreeArray_4_8"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_9_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_9'),"error-messages":_vm.errors['pedigreeArray_4_9']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_9',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_9),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_9", $$v)},expression:"fields.pedigreeArray_4_9"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_5_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_5'),"error-messages":_vm.errors['pedigreeArray_3_5']},on:{"change":function($event){return _vm.updateParent($event, 4, 10)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_5',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_5),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_5", $$v)},expression:"fields.pedigreeArray_3_5"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_10_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_10'),"error-messages":_vm.errors['pedigreeArray_4_10']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_10',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_10),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_10", $$v)},expression:"fields.pedigreeArray_4_10"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_11_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_11'),"error-messages":_vm.errors['pedigreeArray_4_11']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_11',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_11),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_11", $$v)},expression:"fields.pedigreeArray_4_11"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_3_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_3'),"error-messages":_vm.errors['pedigreeArray_2_3']},on:{"change":function($event){return _vm.updateParent($event, 3, 6)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_3',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_3),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_3", $$v)},expression:"fields.pedigreeArray_2_3"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_6_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_6'),"error-messages":_vm.errors['pedigreeArray_3_6']},on:{"change":function($event){return _vm.updateParent($event, 4, 12)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_6',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_6),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_6", $$v)},expression:"fields.pedigreeArray_3_6"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_12_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_12'),"error-messages":_vm.errors['pedigreeArray_4_12']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_12',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_12),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_12", $$v)},expression:"fields.pedigreeArray_4_12"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_13_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_13'),"error-messages":_vm.errors['pedigreeArray_4_13']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_13',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_13),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_13", $$v)},expression:"fields.pedigreeArray_4_13"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_7_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_7'),"error-messages":_vm.errors['pedigreeArray_3_7']},on:{"change":function($event){return _vm.updateParent($event, 4, 14)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_7',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_7),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_7", $$v)},expression:"fields.pedigreeArray_3_7"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_14_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_14'),"error-messages":_vm.errors['pedigreeArray_4_14']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_14',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_14),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_14", $$v)},expression:"fields.pedigreeArray_4_14"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_15_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_15'),"error-messages":_vm.errors['pedigreeArray_4_15']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_15',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_15),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_15", $$v)},expression:"fields.pedigreeArray_4_15"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"16"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_0_1'),"error-messages":_vm.errors['pedigreeArray_0_1']},on:{"change":function($event){return _vm.updateParent($event, 1, 2)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_0_1',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_0_1),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_0_1", $$v)},expression:"fields.pedigreeArray_0_1"}})],1)]),_c('td',{attrs:{"rowspan":"8"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_1_2_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_1_2'),"error-messages":_vm.errors['pedigreeArray_1_2']},on:{"change":function($event){return _vm.updateParent($event, 2, 4)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_1_2',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_1_2),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_1_2", $$v)},expression:"fields.pedigreeArray_1_2"}})],1)]),_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_4_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_4'),"error-messages":_vm.errors['pedigreeArray_2_4']},on:{"change":function($event){return _vm.updateParent($event, 3, 8)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_4',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_4),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_4", $$v)},expression:"fields.pedigreeArray_2_4"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_8_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_8'),"error-messages":_vm.errors['pedigreeArray_3_8']},on:{"change":function($event){return _vm.updateParent($event, 4, 16)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_8',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_8),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_8", $$v)},expression:"fields.pedigreeArray_3_8"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_16_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_16'),"error-messages":_vm.errors['pedigreeArray_4_16']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_16',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_16),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_16", $$v)},expression:"fields.pedigreeArray_4_16"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_17_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_17'),"error-messages":_vm.errors['pedigreeArray_4_17']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_17',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_17),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_17", $$v)},expression:"fields.pedigreeArray_4_17"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_9_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_9'),"error-messages":_vm.errors['pedigreeArray_3_9']},on:{"change":function($event){return _vm.updateParent($event, 4, 18)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_9',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_9),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_9", $$v)},expression:"fields.pedigreeArray_3_9"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_18_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_18'),"error-messages":_vm.errors['pedigreeArray_4_18']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_18',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_18),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_18", $$v)},expression:"fields.pedigreeArray_4_18"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_19_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_19'),"error-messages":_vm.errors['pedigreeArray_4_19']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_19',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_19),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_19", $$v)},expression:"fields.pedigreeArray_4_19"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_5_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_5'),"error-messages":_vm.errors['pedigreeArray_2_5']},on:{"change":function($event){return _vm.updateParent($event, 3, 10)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_5',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_5),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_5", $$v)},expression:"fields.pedigreeArray_2_5"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_10_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_10'),"error-messages":_vm.errors['pedigreeArray_3_10']},on:{"change":function($event){return _vm.updateParent($event, 4, 20)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_10',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_10),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_10", $$v)},expression:"fields.pedigreeArray_3_10"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_20_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_20'),"error-messages":_vm.errors['pedigreeArray_4_20']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_20',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_20),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_20", $$v)},expression:"fields.pedigreeArray_4_20"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_21_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_21'),"error-messages":_vm.errors['pedigreeArray_4_21']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_21',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_21),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_21", $$v)},expression:"fields.pedigreeArray_4_21"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_11_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_11'),"error-messages":_vm.errors['pedigreeArray_3_11']},on:{"change":function($event){return _vm.updateParent($event, 4, 22)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_11',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_11),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_11", $$v)},expression:"fields.pedigreeArray_3_11"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_22_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_22'),"error-messages":_vm.errors['pedigreeArray_4_22']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_22',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_22),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_22", $$v)},expression:"fields.pedigreeArray_4_22"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_23_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_23'),"error-messages":_vm.errors['pedigreeArray_4_23']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_23',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_23),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_23", $$v)},expression:"fields.pedigreeArray_4_23"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"8"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_1_3_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_1_3'),"error-messages":_vm.errors['pedigreeArray_1_3']},on:{"change":function($event){return _vm.updateParent($event, 2, 6)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_1_3',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_1_3),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_1_3", $$v)},expression:"fields.pedigreeArray_1_3"}})],1)]),_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_6_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_6'),"error-messages":_vm.errors['pedigreeArray_2_6']},on:{"change":function($event){return _vm.updateParent($event, 3, 12)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_6',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_6),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_6", $$v)},expression:"fields.pedigreeArray_2_6"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_12_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_12'),"error-messages":_vm.errors['pedigreeArray_3_12']},on:{"change":function($event){return _vm.updateParent($event, 4, 24)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_12',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_12),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_12", $$v)},expression:"fields.pedigreeArray_3_12"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_24_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_24'),"error-messages":_vm.errors['pedigreeArray_4_24']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_24',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_24),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_24", $$v)},expression:"fields.pedigreeArray_4_24"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_25_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_25'),"error-messages":_vm.errors['pedigreeArray_4_25']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_25',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_25),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_25", $$v)},expression:"fields.pedigreeArray_4_25"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_13_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_13'),"error-messages":_vm.errors['pedigreeArray_3_13']},on:{"change":function($event){return _vm.updateParent($event, 4, 26)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_13',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_13),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_13", $$v)},expression:"fields.pedigreeArray_3_13"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_26_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_26'),"error-messages":_vm.errors['pedigreeArray_4_26']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_26',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_26),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_26", $$v)},expression:"fields.pedigreeArray_4_26"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_27_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_27'),"error-messages":_vm.errors['pedigreeArray_4_27']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_27',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_27),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_27", $$v)},expression:"fields.pedigreeArray_4_27"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"4"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_2_7_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_2_7'),"error-messages":_vm.errors['pedigreeArray_2_7']},on:{"change":function($event){return _vm.updateParent($event, 3, 14)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_2_7',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_2_7),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_2_7", $$v)},expression:"fields.pedigreeArray_2_7"}})],1)]),_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_14_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_14'),"error-messages":_vm.errors['pedigreeArray_3_14']},on:{"change":function($event){return _vm.updateParent($event, 4, 28)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_14',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_14),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_14", $$v)},expression:"fields.pedigreeArray_3_14"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_28_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_28'),"error-messages":_vm.errors['pedigreeArray_4_28']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_28',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_28),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_28", $$v)},expression:"fields.pedigreeArray_4_28"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_29_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_29'),"error-messages":_vm.errors['pedigreeArray_4_29']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_29',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_29),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_29", $$v)},expression:"fields.pedigreeArray_4_29"}})],1)])]),_c('tr',[_c('td',{attrs:{"rowspan":"2"}},[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_3_15_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_3_15'),"error-messages":_vm.errors['pedigreeArray_3_15']},on:{"change":function($event){return _vm.updateParent($event, 4, 30)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_3_15',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_3_15),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_3_15", $$v)},expression:"fields.pedigreeArray_3_15"}})],1)]),_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_30_Disabled,"label":"Dam","items":_vm.dams,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_30'),"error-messages":_vm.errors['pedigreeArray_4_30']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_30',
                                  'F'
                                )}}},[_vm._v("Add New Dam")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_30),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_30", $$v)},expression:"fields.pedigreeArray_4_30"}})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"button_select"},[_c('v-autocomplete',{attrs:{"hide-no-data":"","disabled":_vm.fields.pedigreeArray_4_31_Disabled,"label":"Sire","items":_vm.sires,"item-text":"kennel_name","return-object":"","item-value":"id","outlined":"","background-color":"white","error":_vm.errors.hasOwnProperty('pedigreeArray_4_31'),"error-messages":_vm.errors['pedigreeArray_4_31']},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"append"},[_c('v-btn',{on:{"click":function($event){return _vm.$refs.dogForm.openForm(
                                  'pedigreeArray_4_31',
                                  'M'
                                )}}},[_vm._v("Add New Sire")])],1)]},proxy:true}]),model:{value:(_vm.fields.pedigreeArray_4_31),callback:function ($$v) {_vm.$set(_vm.fields, "pedigreeArray_4_31", $$v)},expression:"fields.pedigreeArray_4_31"}})],1)])])])])])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400","retain-focus":false},model:{value:(_vm.confirmSave.dialog),callback:function ($$v) {_vm.$set(_vm.confirmSave, "dialog", $$v)},expression:"confirmSave.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirm")]),_c('v-card-text',[_vm._v("Are you sure you want to save?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetPedigree}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.confirmSave.loading},on:{"click":_vm.savePedigree}},[_vm._v("Yes")])],1)],1)],1),_c('DogForm',{ref:"dogForm",attrs:{"pedigree":this.fields}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }