<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Dog</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-text-field
            label="Name*"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    pedigree: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        pedigree: null,
        name: null,
        kennel_name: null,
        gender: "F",
        breed_id: null,
        location: "unknown",
        status: "breeding-dog",
      },

      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],

      statuses: [
        { name: "Breeding Dog", value: "breeding-dog" },
        { name: "Speyed/Neutered", value: "speyed" },
        { name: "Breeding Complete", value: "breeding-complete" },
        { name: "Puppy", value: "puppy" },
        { name: "External", value: "external" },
      ],

      locations: [
        { name: "LHL", value: "lhl" },
        { name: "FCH", value: "fch" },
        { name: "Breeder Colleague", value: "breeder-colleague" },
        { name: "Customer", value: "customer" },
        { name: "External", value: "external" },
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },

    australian_labradoodle() {
      let breed = this.breeds.filter((breed) => {
        return breed.name == "Australian Labradoodle";
      });

      return breed[0].id;
    },
  },

  methods: {
    openForm: function (fieldName, gender) {
      this.dialog = true;
      this.fields.fieldName = fieldName;
      this.fields.gender = gender;
      this.fields.breed_id = this.australian_labradoodle;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.fields.kennel_name = this.fields.name;

      this.fields.form = "pedigree";
      this.fields.dogId = this.$route.params.dogId;

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          // ...this.pedigree,
          ...this.fields,
        },
      };

      this.$store
        .dispatch("lhl/dogs/saveDog", payload)
        .then((resp) => {
          this.pedigree[this.fields.fieldName] = resp.data.dog;

          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.gender = "F";
      this.fields.breed_id = null;
      this.fields.location = "unknown";
      this.fields.status = "breeding-dog";
    },
  },
};
</script>
